import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ExportTemplate } from 'state-domains/domain/subscription';
import { buildExportTemplateWithIdUrl } from 'state-domains/network/urls';
import { convertToCamel, convertToSnake, sendRequestWithXSRFToken } from 'state-domains/utils';

export const saveExportTemplate = (
    payload: Partial<ExportTemplate>,
): Observable<ExportTemplate> => {
    const url = buildExportTemplateWithIdUrl(payload?.id ?? '');
    return sendRequestWithXSRFToken(url, convertToSnake(payload), payload.id ? 'PUT' : 'POST').pipe(
        switchMap(({ response }: AjaxResponse<ExportTemplate>) => {
            const savedTemplate = convertToCamel<ExportTemplate>(response);
            return observableOf(savedTemplate);
        }),
    );
};
